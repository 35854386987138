//COLORS

//Light Blues
$light-blue-primary: #1987cb;
$light-blue-secondary: #1275b3;

@mixin light-blue-filter {
    // equal to $light-blue-primary, is needed for images
    filter: invert(46%) sepia(82%) saturate(3456%) hue-rotate(179deg)
        brightness(90%) contrast(80%);
}

@mixin white-filter {
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(24deg)
        brightness(106%) contrast(102%);
}

//Dark Blues
$dark-blue-primary: #0b496e;
$dark-blue-secondary: #192e3c; //#122B3B

//Shades of Blue
$blue-shade-0: #f3f7fa; //Body BG color
// $blue-shade-1: #EAEFF6;
$blue-shade-1: #eef5ff;
$blue-shade-2: #dfe7f2;
$blue-shade-3: #bec9da;
$blue-shade-4: #98a6bc;
$blue-shade-5: #6d808d;
$blue-shade-6: #48585f; //body text color
$blue-shade-7: #18374a; //#2A3F4C
$blue-shade-8: #202b32; //new navigation background color

$blue-border-8: #2f383d; // border for blue-shade-8 area

$inputFocusColor: #51cbeeff; //rgba(81, 203, 238, 1);

//Shades of Gray
$white: #ffffff;
$gray-shade-1: #f7f7f7;
$gray-shade-2: #f1f3f4;
$gray-shade-3: #eeeeee;
$gray-shade-4: #d7d7d7;
$gray-shade-5: #b6b6b6;
$gray-shade-6: #909090;

//SCORE COLORS
//Mild
$mild-light: #e2f5cb;
$mild: #6db310;
$mild-dark: #457a00;
//Moderate
$moderate-light: #fff5d9; //#FAE6D8;
$moderate: #f2b92b; //#F87A26;
$moderate-dark: #e1a001; //#D75A07;
$moderate-pale: #ffeed3;
//Severe
$severe-light: #fee1e4;
$severe: #e90019;
$severe-dark: #b20000;
//Extreme
$extreme-light: #da9ddb;
$extreme: #af19b2;
$extreme-dark: #970798;

//Neutral
$neutral-50: #f8f9fb;
$neutral-100: #f5f7fa;
$neutral-150: #f2f4f8; //light mode body background
$neutral-200: #eceff4;
$neutral-250: #e5e9f0;
$neutral-300: #dfe4ec;
$neutral-350: #d8dfe9;
$neutral-400: #d1dae5;
$neutral-450: #cbd4e1;
$neutral-500: #c4cfde; //dark mode text color
$neutral-550: #bec9da;
$neutral-600: #a4b3cb;
$neutral-650: #91a0b5;
$neutral-700: #79879a;
$neutral-750: #6c7889;
$neutral-800: #5c6370;
$neutral-850: #4c5057;
$neutral-900: #393c42; //light mode text color
$neutral-950: #26282c; //Contrast button // dark mode body background
$neutral-975: #1a1a1c;

//Blue
$blue-50: #f7fbfd;
$blue-100: #e6f2fa;
$blue-200: #d1e7f5;
$blue-300: #a3cfea;
$blue-400: #75b7e0;
$blue-500: #479fd5;
$blue-600: #1987cb; //Body link, primary button
$blue-700: #146ca2;
$blue-800: #0f517a;
$blue-900: #0a3651;
$blue-950: #051b29;

//Green
$green-50: #fafdf7;
$green-100: #f1f8e8;
$green-200: #e2f0cf;
$green-300: #c5e19f;
$green-400: #a7d170;
$green-500: #8ac240;
$green-600: #6db310; //Success button
$green-700: #578f0d;
$green-800: #416b0a;
$green-900: #2c4806;
$green-950: #162403;

//Orange
$orange-50: #fefcf6;
$orange-100: #fdf8ec;
$orange-200: #fbeece;
$orange-300: #f7dd9d;
$orange-400: #f3cc6d;
$orange-500: #efbb3c;
$orange-600: #ebaa0b; //Warning button
$orange-700: #bc8809;
$orange-800: #8d6607;
$orange-900: #5e4404;
$orange-950: #2f2202;

//Red
$red-50: #fef5f6;
$red-100: #fde3e5;
$red-200: #fbccd1;
$red-300: #f699a3;
$red-400: #f26675;
$red-500: #ed3347;
$red-600: #e90019; //Danger button
$red-700: #ba0014;
$red-800: #8c000f;
$red-900: #5d000a;
$red-950: #2f0005;

//Purple
$purple-50: #fbf5ff;
$purple-100: #f7ebfe;
$purple-200: #efd9fd;
$purple-300: #dfb2fa;
$purple-400: #cf8cf8;
$purple-500: #bf65f5;
$purple-600: #af3ff3;
$purple-700: #8c32c2;
$purple-800: #692692;
$purple-900: #461961;
$purple-950: #230d31;

//Tones
$light-bg-tone: $neutral-150;
$dark-bg-tone: $neutral-950;
$content-tone-1: $neutral-950;
$content-tone-2: $neutral-800;
$content-tone-3: $neutral-500;
$content-tone-4: $neutral-100;

//Accent Colors
$blue-primary: $blue-600;
$blue-light: $blue-300;
$blue-dark: $blue-900;
$green-primary: $green-600;
$green-light: $green-300;
$green-dark: $green-900;
$orange-primary: $orange-600;
$orange-light: $orange-300;
$orange-dark: $orange-900;
$red-primary: $red-600;
$red-light: $red-300;
$red-dark: $red-900;

//FONTS
//$family: 'Montserrat', sans-serif;
$family: "Roboto", sans-serif;
$family-cond: "Roboto Condensed", sans-serif;

//FONT-WEIGHTS
//ROBOTO REGULAR OPTIONS: thin, reg, medium, bold
//ROBOTO CONDENSED OPTIONS: thin, reg, bold
$light: 300;
$reg: 400;
$medium: 500;
$bold: 700;

$frat-width: 720px;

$header-height: 60px;
$mobile-header-height: 78px;
$expanded-header-height: 87px;
$mobile-expanded-header-height: 80px;
$form-editor-mobile-expanded-header-height: 116px; // $mobile-expanded-header-height + 38px

//BUTTONS

//Formatting
$borderRadius: 5px;
$primeIconFontSize: 0.625rem;

//Padding
$buttonPadding: 0.375rem 0.8125rem;
$buttonIconOnlyPadding: 0.375rem 0;
$buttonIconOnlyWidth: 1.875rem;

//Colors
//-Primary
$buttonBg: $blue-primary;
$buttonHoverBg: $blue-dark;
$buttonHoverBorderColor: $blue-dark;
$buttonActiveBg: $blue-primary;
$buttonActiveBorderColor: $blue-primary;

//-Secondary
$secondaryButtonBg: transparent;
$secondaryButtonBorder: 1px $blue-primary solid;
$secondaryButtonTextColor: $blue-primary;
$secondaryButtonHoverBg: transparent;
$secondaryButtonHoverBorderColor: $blue-dark;
$secondaryButtonTextHoverColor: $blue-dark;
$secondaryButtonActiveBg: $blue-100;
$secondaryButtonActiveBorderColor: $blue-primary;
$secondaryButtonTextActiveColor: $blue-primary;

//-Success
$successButtonBg: $green-primary;
$successButtonBorder: 1px $green-primary solid;
$successButtonHoverBg: $green-dark;
$successButtonHoverBorderColor: $green-dark;
$successButtonActiveBg: $green-primary;
$successButtonActiveBorderColor: $green-primary;

//-Info
$infoButtonBg: $blue-primary;
$infoButtonBorder: 1px $blue-primary solid;
$infoButtonHoverBg: $blue-dark;
$infoButtonHoverBorderColor: $blue-dark;
$infoButtonActiveBg: $blue-primary;
$infoButtonActiveBorderColor: $blue-primary;

//-Warning
$warningButtonBg: $orange-primary;
$warningButtonBorder: 1px $orange-primary solid;
$warningButtonHoverBg: $orange-dark;
$warningButtonHoverBorderColor: $orange-dark;
$warningButtonActiveBg: $orange-primary;
$warningButtonActiveBorderColor: $orange-primary;

//-Danger
$dangerButtonBg: $red-primary;
$dangerButtonBorder: 1px $red-primary solid;
$dangerButtonHoverBg: $red-dark;
$dangerButtonHoverBorderColor: $red-dark;
$dangerButtonActiveBg: $red-primary;
$dangerButtonActiveBorderColor: $red-primary;

//-Contrast
$contrastButtonBg: $neutral-950;
$contrastButtonBorder: 1px $neutral-950 solid;
$contrastButtonHoverBg: $neutral-700;
$contrastButtonHoverBorderColor: $neutral-700;
$contrastButtonActiveBg: $neutral-950;
$contrastButtonActiveBorderColor: $neutral-950;
