.p-selectbutton {
    .p-button {
        background: $neutral-200; //$toggleButtonBg;
        border: 2px solid $neutral-250; // $toggleButtonBorder;
        color: $neutral-900; // $toggleButtonTextColor;
        transition: $formElementTransition;
        box-shadow: none !important;

        @media (prefers-color-scheme: dark) {
            background: $neutral-850;
            border: 2px solid $neutral-900;
            color: $neutral-200;
        }

        .p-button-icon-left,
        .p-button-icon-right {
            color: $toggleButtonIconColor;
        }

        &:not(.p-disabled):not(.p-highlight):hover {
            background: $neutral-50; // $toggleButtonHoverBg;
            border-color: $neutral-250; // $toggleButtonHoverBorderColor;
            color: $blue-600; // $toggleButtonTextHoverColor;

            @media (prefers-color-scheme: dark) {
                background: $neutral-800;
                border-color: $neutral-900;
                color: white;
            }

            .p-button-icon-left,
            .p-button-icon-right {
                color: $toggleButtonIconHoverColor;
            }
        }

        &.p-highlight {
            background: $white; // $toggleButtonActiveBg;
            color: $blue-600; // $toggleButtonTextActiveColor;

            @media (prefers-color-scheme: dark) {
                background: $neutral-975;
                color: $blue-600;
            }

            .p-button-icon-left,
            .p-button-icon-right {
                color: $toggleButtonIconActiveColor;
            }

            &:hover {
                background: $neutral-50; // $toggleButtonActiveHoverBg;
                border-color: $neutral-250; // $toggleButtonActiveHoverBorderColor;
                color: $blue-600; // $toggleButtonTextActiveHoverColor;

                @media (prefers-color-scheme: dark) {
                    background: $neutral-800;
                    border-color: $neutral-900;
                    color: white;
                }

                .p-button-icon-left,
                .p-button-icon-right {
                    color: $toggleButtonIconActiveHoverColor;
                }
            }
        }
    }
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    @include invalid-input();
}
