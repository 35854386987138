@import "partials/variables";
@import "partials/mixins";

.table-filter-container {
    // @include flex();
    // align-items: center;
    // justify-content: space-between;

    .actions {
        @include flex();
        gap: 10px;
        @include bp(browser) {
            margin-top: -30px;
        }
        @include bp(med-browser) {
            margin-top: 2px;
        }
        button {
            height: 30px !important;
        }

        .btn-primary {
            padding: 0 10px;
        }
    }
}

.table-filters {
    @include table-filters();
}

.table-empty-state {
    @include flex();
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 320px;
    text-align: center;
    height: 60vh;
    margin: 0 auto;

    .empty {
        width: 100%;
        // margin-top: 100px;
    }

    // @include bp(browser) {
    //     .empty {
    //         margin-top: 150px;
    //     }
    // }

    h2 {
        color: $content-tone-2;
        margin: 0 auto 10px;
        @include ff($family-cond, $bold);

        @media (prefers-color-scheme: dark) {
            color: $content-tone-3;
        }
    }

    p {
        // padding: 0 35px;
        line-height: 20px;
        margin: 0 auto;
        color: $content-tone-2;

        @media (prefers-color-scheme: dark) {
            color: $content-tone-3;
        }
    }

    img {
        margin: 0 auto;
    }

    .new-btn {
        @include flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        gap: 5px;
        cursor: pointer;
        @include ff($family-cond, $bold);
        font-size: 14px;
        padding: 10px;
        color: $white;
        margin: 1rem auto 0;
        background: $dark-blue-primary;
        @include radius(4px);
        text-decoration: none;

        img {
            margin: 0;
        }

        &:hover {
            background-color: darken($dark-blue-primary, 5%);
        }
    }
}

.table-card-list {
    @include flex();
    flex-direction: column;
    gap: 10px;

    .item-card {
        @include flex();
        align-items: center;
    }
}
