@import "partials/variables";
@import "partials/mixins";
@import "partials/tooltips";
@import "partials/sizing";

$brand-height: 60px;

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-sizing: inherit;
    &:before,
    &:after {
        box-sizing: inherit;
    }
}

html {
    // overflow: hidden;
    // height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: $family-cond;
    // background: red;
    background-color: $light-bg-tone;
    @include scrollbars(0, white, white);

    @media (prefers-color-scheme: dark) {
        background-color: $dark-bg-tone;
    }

    @extend %text-body;
}

//VERTICAL SPACING
[class^="stack"] > * {
    margin-block: 0;
}
.stack-xxs > * + * {
    margin-block-start: 2px;
}
.stack-xs > * + * {
    margin-block-start: 4px;
}
.stack-sm > * + * {
    margin-block-start: 8px;
}
.stack > * + * {
    margin-block-start: 16px;
}
.stack-lg > * + * {
    margin-block-start: 32px;
}

//PADDING
.padding-xxs {
    padding: 2px;
}
.padding-xs {
    padding: 4px;
}
.padding-sm {
    padding: 8px;
}
.padding {
    padding: 16px;
}
.padding-md {
    padding: 24px;
}
.padding-lg {
    padding: 32px;
}

// main {
//   overflow: hidden;
// }

header {
    background-color: $dark-blue-primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $content-tone-1;

    @media (prefers-color-scheme: dark) {
        color: $content-tone-4;
    }
}

h1 {
    @include ff($family-cond, $bold);
    font-size: 24px;
}

h2 {
    @include ff($family-cond, $bold);
    font-size: 20px;
}

h3 {
    @include ff($family-cond, $bold);
    font-size: 18px;
}

h4 {
    @include ff($family-cond, $bold);
    font-size: 16px;
}

h5 {
    @include ff($family-cond, $bold);
    font-size: 14px;
}

h6 {
    @include ff($family-cond, $medium);
    font-size: 12px;
    margin: 0;
}

hr {
    border-top: 1px solid $blue-shade-2;
    border-right: none;
    border-bottom: none;
    border-left: none;
    margin: 30px 0;

    @media (prefers-color-scheme: dark) {
        border-top: 1px solid $neutral-850;
    }
}

/* Using plain CSS */
@media (min-width: 768px) {
    .tooltip {
        position: relative;
        display: inline-block;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 4px;
        padding: 5px;
        font-size: 12px;
        font-weight: bold;
    }

    // TODO: does work only for placement on the left
    // .tooltip:before{
    //   content:'';
    //   display:block;
    //   width:0;
    //   height:0;
    //   position:absolute;
    //   border-top: 8px solid transparent;
    //   border-bottom: 7px solid transparent;
    //   border-left:8px solid black;
    //   right:-8px;
    //   top:8px;
    // }
}

@media (max-width: 767px) {
    .tooltip {
        display: none;
        visibility: hidden;
    }
}

[ng\:cloak],
[ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none !important;
}

//BUTTONS
.btn {
    width: 100%;
}
.btn {
    @extend %btn;
}

.btn-primary {
    @extend %btn-primary;
}

.btn-secondary {
    @extend %btn-secondary;
}

.btn-secondary-simple {
    @extend %btn-secondary;
    border: none !important;
    background: none !important;
    height: fit-content !important;
}

.btn-tertiary {
    @extend %btn-tertiary;
}

.btn-quaternary {
    @extend %btn-quaternary;
}

.btn-disabled,
button[disabled] {
    @extend %btn-disabled;
}

.btn-disabled-light {
    @extend %btn-disabled-light;
}

.btn-remove {
    @extend %btn-remove;
}

a.link {
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    @include ff($family-cond, $bold);
    display: block;
    margin: 20px auto 10px;
    color: $light-blue-primary;
    &.delete {
        color: $dark-blue-primary;
    }
    svg {
        margin-right: 5px;
        #icon-add,
        #link-account,
        #switch-account,
        #reveal {
            fill: $light-blue-primary;
        }
        #trash {
            fill: $dark-blue-primary;
        }
    }
    @include bp(browser) {
        + a.link {
            margin-bottom: 0;
        }
    }
    &:hover {
        color: $light-blue-secondary;
        &.delete {
            color: $dark-blue-secondary;
        }
        svg {
            #icon-add,
            #link-account,
            #switch-account {
                fill: $light-blue-secondary;
            }
            #trash {
                fill: $dark-blue-secondary;
            }
        }
    }
}

a.textlink {
    @include flex();
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    text-align: left;
    @include ff($family-cond, $bold);
    font-size: 14px;
    line-height: 24px;
    font-style: italic;
    margin: 0;
    color: $light-blue-primary;
    cursor: pointer;
    font-style: normal;
    svg {
        fill: $light-blue-primary;
    }
    &:hover {
        color: $light-blue-secondary;
    }
}
.textlink {
    // gap: 8px;
    width: fit-content;
    svg path {
        fill: $light-blue-primary;
    }
}

.breadcrumb {
    @include flex();
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    flex-basis: auto;
    a {
        @include flex();
        align-items: center;
        @include ff($family-cond, $bold);
        font-style: normal;
        font-size: 12px;
        color: $blue-shade-5;
        text-decoration: none;
        margin-bottom: 10px;
        cursor: pointer;

        @media (prefers-color-scheme: dark) {
            color: $content-tone-3;
        }

        svg {
            margin: 0 4px;
        }
        svg #arrow {
            fill: $blue-shade-5;
        }
        @include bp(browser) {
            &:hover {
                color: $blue-primary;
                svg #arrow {
                    fill: $blue-primary;
                }
            }
        }
        img {
            margin: 0;
        }
        span {
            display: block;
        }
    }
}

.app-container {
    @include flex();
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    // background: $blue-shade-0;
    @include scrollbars(0, white, white);

    .app-sidebar {
        @include flex();
        flex-direction: column;
        justify-content: flex-start;
        background: $white;
        width: 100%;
        min-width: 250px;
        max-width: 400px;
        overflow-x: hidden;
        overflow-y: scroll;
        height: 100vh;

        @media (prefers-color-scheme: dark) {
            background: $neutral-975;
        }

        @include scrollbars(0, $blue-shade-7, $blue-shade-7);
        @include bp(browser) {
            overflow-y: hidden;
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            z-index: 40;
            max-width: 250px;
            padding-bottom: 0;
        }

        .navwrap {
            height: calc(100vh - 60px); // $brand-height
            @include flex();
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            margin-bottom: 50px;
            @include bp(browser) {
                margin-bottom: 0;
            }
            overflow-x: hidden;
            overflow-y: scroll;
            @include scrollbars(0, $blue-shade-7, $blue-shade-7);
        }

        .brand {
            min-height: $brand-height;
            @include flex();
            align-items: center;
            justify-content: space-between;
            padding: 0 1rem;
            border-bottom: 1px solid $blue-100;

            @media (prefers-color-scheme: dark) {
                border-bottom: 1px solid $neutral-950;
            }

            .app-logo {
                .logo-symbol {
                    fill: $blue-primary;
                }
                .logo-preflight,
                .logo-mitigator {
                    fill: $content-tone-1;

                    @media (prefers-color-scheme: dark) {
                        fill: $content-tone-4;
                    }
                }
            }
        }

        .leftnav {
            @include flex();
            flex-direction: column;
            justify-content: flex-start;
            // flex-grow: 1;
            height: calc(100% - 120px);
            @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
                height: calc(100% - 150px);
            }
        }
    }
    .app-main {
        @include flex();
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        min-height: 100vh;
        &.settings {
            overflow-y: hidden;
            overflow-x: hidden;
        }
    }
    @include bp(browser) {
        .app-main {
            margin-left: 250px;
        }
    }
    .app-content {
        align-self: center;
        width: 100%;
        @include flex();
        flex-direction: column;
        position: relative;
        padding: 1.25rem 1.25rem 150px;
        @include bp(browser) {
            padding: 1.5rem 1.5rem 110px; // todo: check it, probably, 1.5 1.5 3.
        }
    }
    &.settings {
        .app-topbar {
            min-height: $header-height;
            width: 100vw;
        }
        @include bp(browser) {
            .app-topbar {
                left: 250px;
                width: calc(100vw - 250px);
                min-height: $header-height;
            }
        }
        // .app-content {
        //     top: $form-editor-mobile-expanded-header-height;
        //     @include bp(browser) {
        //         top: $expanded-header-height;
        //     }
        // }
    }
}

.notice-warning {
    background-color: $moderate-light;
    @include radius(4px);
    padding: 10px;
    p {
        margin: 10px 0 0 0;
        font-size: 14px;
        text-align: left;
        padding: 0;
        margin: 0;
    }
}

//FORM STYLES
// .ng-valid
// .ng-invalid
// .ng-pending
// .ng-pristine
// .ng-dirty
// .ng-untouched
// .ng-touched
.issue-notice {
    color: $white;
    font-size: 11px;
    @include ff($family-cond, $medium);
    margin: 3px 0 0 0;
    padding: 3px 5px;
    // @include radii(0, 0, 3px, 3px);
    @include radius(3px);
    background: $red-600;
    .onboarding & {
        color: white;
    }
}

.onboarding-screen {
    label {
        color: white;
    }
}

label {
    color: $content-tone-2;
    @include ff($family-cond, $bold);
    font-size: 14px;
    margin: 0 0 5px;
    display: block;

    @media (prefers-color-scheme: dark) {
        color: $content-tone-3;
    }

    a {
        color: $blue-primary;
    }
}
input:not(.p-element),
input.form-control:not(.p-element),
select:not(.p-element),
select.form-control:not(.p-element),
textarea.form-control:not(.p-element) {
    height: 40px;
}
input,
input.form-control,
select,
select.form-control,
textarea.form-control {
    @include ff($family-cond);
    border: 1px solid $neutral-650;
    color: $neutral-950;
    width: 100%;
    margin: 0;
    font-size: 14px;
    background-color: $white;
    @include radius(4px);

    @media (prefers-color-scheme: dark) {
        border: 1px solid $neutral-800;
        background-color: $neutral-950;
        color: $white;
    }

    &:-webkit-autofill {
        border: 1px solid $neutral-650;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        -webkit-text-fill-color: $neutral-950 !important;

        @media (prefers-color-scheme: dark) {
            border: 1px solid $neutral-800;
            -webkit-box-shadow: 0 0 0 30px $neutral-950 inset !important;
            -webkit-text-fill-color: $white !important;
        }
    }

    .placeholder,
    &::placeholder,
    &::placeholder-shown {
        color: $neutral-650;
    }
    .onboarding & {
        border: none;
    }
}
textarea.form-control {
    min-height: 40px;
}
input:not(.p-element),
select:not(.p-element) {
    text-indent: 10px;
}
textarea.ng-touched.ng-invalid {
    border: 1px solid $red-600;
    color: $content-tone-1;
    background: $red-100;

    @media (prefers-color-scheme: dark) {
        color: $content-tone-4;
        background: $red-900;
    }
}
input {
    &[type="text"],
    &[type="password"],
    &[type="email"],
    &[type="tel"] {
        -webkit-appearance: none;
        &.ng-touched.ng-invalid {
            border: 1px solid $red-600;
            color: $content-tone-1;
            background: $red-100;

            @media (prefers-color-scheme: dark) {
                color: $content-tone-4;
                background: $red-900;
            }
        }
        min-height: 40px;
    }
    &[type="radio"] {
        width: 20px;
        height: 20px;
    }
    &[disabled] {
        background: $neutral-100;
        color: $neutral-650;

        @media (prefers-color-scheme: dark) {
            background: $neutral-850;
            color: $neutral-650;
        }
    }
}
input[type="time"]::-webkit-calendar-picker-indicator {
    @media (prefers-color-scheme: dark) {
        filter: invert(100%);
    }
}
select {
    cursor: pointer;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
    background-position:
        calc(100% - 18px) 50%,
        calc(100% - 13px) 50%,
        calc(100% - 2.5em) 50%;
    @include bp(mini) {
        background-position:
            calc(100% - 18px) calc(1em + 4px),
            calc(100% - 13px) calc(1em + 4px),
            calc(100% - 2.5em) 0.65em;
    }
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
    background-repeat: no-repeat;
    &[disabled] {
        background: $neutral-100;
        color: $neutral-650;

        @media (prefers-color-scheme: dark) {
            background: $neutral-850;
            color: $neutral-650;
        }
    }
}

input[type="text"],
input[type="email"],
input[type="file"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="time"],
input,
select,
textarea {
    border-style: solid;
    -webkit-appearance: none;
    box-shadow: none;
    //    -webkit-transition: all 0.3s ease-in-out;
    //    -moz-transition: all 0.3s ease-in-out;
    //    -ms-transition: all 0.3s ease-in-out;
    //    -o-transition: all 0.3s ease-in-out;
}

select:focus,
input:focus,
textarea:focus {
    outline: none;
    //    -moz-box-shadow: 0 0 5px $inputFocusColor;
    //    -webkit-box-shadow: 0 0 5px $inputFocusColor;
    //    box-shadow: 0 0 5px $inputFocusColor;
    border: 1px solid $blue-600;

    @media (prefers-color-scheme: dark) {
        border: 1px solid $blue-500;
    }
}

textarea {
    border: 1px solid $neutral-650;
    padding: 10px;
    margin: 20px 0;
    min-height: 80px;
    resize: vertical;
    font-size: 14px;
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: stretch;

    @media (prefers-color-scheme: dark) {
        border: 1px solid $neutral-700;
    }

    .onboarding & {
        border: none;
    }
}

textarea:disabled {
    background-color: $neutral-100;
    color: $neutral-650;
    border: 1px solid $neutral-650;
    font-style: italic !important;
    cursor: not-allowed;

    @media (prefers-color-scheme: dark) {
        background-color: $neutral-850;
        color: $neutral-650;
        border: 1px solid $neutral-700;
    }
}

input[type="checkbox"] {
    background-color: white;
    border: 1px solid $blue-shade-3;
    width: 20px;
    height: 20px;
    @include radius(3px);
}

textarea,
select,
input,
button,
textarea:focus,
select:focus,
input:focus,
button:focus,
label:focus,
input[type="radio"]:focus {
    outline: none !important;
    outline-width: 0;
}
textarea,
select,
input,
button {
    outline: none;
}

*:focus {
    outline: none;
}

*::placeholder {
    color: $blue-shade-4;
}

[contenteditable="true"]:focus {
    outline: none;
}

.error-text {
    display: none;
}

.has-error .help-block.error-text {
    display: block;
}

.has-error .help-inline.error-text {
    display: inline;
}

.date-text {
    font-size: 10px;
    color: $blue-shade-4;
    @include ff($family-cond, $medium);
}

.message {
    //margin-bottom: 10px;
    overflow: hidden;
}

input.form-control.error {
    border: 1px solid $severe;
    color: $severe;
}

.flights .app-topbar,
.stats .app-topbar {
    flex-direction: column;
    .main-header {
        @include flex();
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto;
        @include bp(belowtablet) {
            padding: 0 0.75rem;
        }
        padding: 0 1rem;
        @include bp(browser) {
            padding: 0 1.5rem;
        }
        width: 100%;
        &.desktop {
            display: none;
        }
        @include bp(tablet-horizontal) {
            &.mobile {
                display: none;
            }
            &.desktop {
                @include flex();
            }
        }
    }
    #main-nav {
        height: 100%;
        ul {
            list-style-type: none;
            @include flex();
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            margin: 0;
            padding: 0;
            li {
                height: 50px;
                border-bottom: 4px solid $blue-shade-6;
                margin: 0 20px 0 0;
                @include flex();
                align-items: center;
                align-content: center;
                @include ff($family-cond, $bold);
                font-size: 14px;
                cursor: pointer;
                color: white;
                &:hover {
                    border-color: $blue-shade-5;
                }
                &.active {
                    border-color: $light-blue-primary;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .mobile-toggle-nav {
        width: 100vw;
        background: $blue-shade-7;
        @include bp(tablet-horizontal) {
            display: none;
        }
        ul {
            @include flex();
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            list-style-type: none;
            height: 30px;
            padding: 0;
            margin: 0;
        }
        li {
            @include flex();
            align-items: center;
            justify-content: center;
            height: 100%;
            @include ff($family-cond, $bold);
            text-transform: uppercase;
            font-size: 12px;
            text-align: center;
            width: 50%;
            border-bottom: 3px solid $blue-shade-6;
            cursor: pointer;
            color: white;
            opacity: 0.5;
            &.active {
                opacity: 1;
                border-color: white;
            }
        }
    }
    .middle {
        width: 30%;
        min-width: 50px;
        height: 50px;
        @include flex();
        justify-content: center;
        align-items: center;
        @include bp(browser) {
            min-width: 200px;
        }
    }
    .left,
    .right {
        @include flex();
        flex-direction: row;
        height: 50px;
        align-items: center;
        width: 35%;
    }
    .left {
        justify-content: flex-start;
    }
    .right {
        justify-content: flex-end;
    }
    img.account-menu {
        margin: 0;
        cursor: pointer;
    }
    svg.account-menu {
        cursor: pointer;
        fill: white;
    }
    #logo {
        cursor: pointer;
        @include flex();
        align-items: center;
        .desktop {
            display: none;
        }
        @include bp(browser) {
            .desktop {
                @include flex();
            }
            .mobile {
                display: none;
            }
        }
    }
    #edit-flight,
    #close-add,
    #close-account,
    #account,
    #filter {
        cursor: pointer;
        span.account-menu,
        .menu-svg {
            height: 24px;
            width: 24px;
            display: block;
            object-fit: contain;
            fill: currentColor;
            color: $blue-shade-5;
        }
    }
    .new-frat-btn {
        @include bp(belowdesktop) {
            display: none;
        }
        cursor: pointer;
        margin-right: 20px;
        @include flex();
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        @include ff($family-cond, $bold);
        font-size: 12px;
        text-transform: uppercase;
        height: 30px;
        padding: 0 10px;
        color: $white;
        background: $mild;
        @include radius(4px);
        text-decoration: none;
        &:hover {
            background-color: darken($mild, 5%);
        }
        img {
            margin: 0 8px 0 0;
        }
        span {
            height: 16px;
            line-height: 16px;
        }
    }
    .export-pdf-btn {
        @extend .new-frat-btn;
        img {
            margin: 0 0 0 8px;
        }
        background: $light-blue-primary;
        &:hover {
            background-color: $light-blue-secondary;
        }
    }
}

.frat-calculation {
    .header .header-wrap {
        background: transparent;
        border-bottom: none;
    }
}

.left-corner-control,
.right-corner-control {
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    @include flex();
    justify-content: center;
    align-items: center;
    a {
        @include flex();
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        text-decoration: none;
        @include radius(5px);
        height: 40px;
        min-width: 40px;
        padding: 15px 15px;
        @include bp(browser) {
            background: none;
            height: auto;
        }
        span {
            white-space: nowrap;
            height: 16px;
            line-height: 18px;
            font-size: 16px;
            color: white;
            @include ff($family-cond, $bold);
            // text-transform: uppercase;
            display: block;
            position: relative;
            margin: 0 0 2px 0;
            visibility: visible;
            opacity: 1;
            -webkit-transition:
                visibility 0s linear 0s,
                opacity 0.24s;
            -moz-transition:
                visibility 0s linear 0s,
                opacity 0.24s;
            -o-transition:
                visibility 0s linear 0s,
                opacity 0.24s;
            transition:
                visibility 0s linear 0s,
                opacity 0.24s;
            &.collapsed {
                display: none;
                opacity: 0;
                -webkit-transition:
                    visibility 0s linear 0.1s,
                    opacity 0.1s;
                -moz-transition:
                    visibility 0s linear 0.1s,
                    opacity 0.1s;
                -o-transition:
                    visibility 0s linear 0.1s,
                    opacity 0.1s;
                transition:
                    visibility 0s linear 0.1s,
                    opacity 0.1s;
            }
        }
    }
    @include bp(browser) {
        &.mobile {
            display: none;
        }
    }
    &.green a {
        background: $mild;
    }
    &.edit-flight a {
        background: $light-blue-secondary;
    }
    &.blue a {
        background: $light-blue-secondary;
    }
    &.black a {
        background-color: $neutral-500;
        fill: $content-tone-1;

        @media (prefers-color-scheme: dark) {
            background-color: $neutral-800;
            fill: $content-tone-4;
        }
    }
    &.scrolled {
        span {
            display: none;
        }
    }
}

.left-corner-control {
    z-index: 32;
    left: 20px;
    a {
        padding: 13px;
    }
    &.black {
        height: auto;
        a {
            height: auto;
            margin: 0;
            background-color: $blue-primary;
            fill: $white;

            img {
                width: 24px !important;
                height: 24px !important;
                margin: 0 !important;
            }
            &.menu {
                @include radius(50%);
            }
            &.close {
                @include radius(50%);
                background-color: $blue-primary;
            }
        }
    }
}

.right-corner-control {
    right: 20px;
    z-index: 30;
}

.circle {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    background: #fff;
    color: #76c931;
}

.circle .height_fix {
    margin-top: 100%;
}

.circle .content {
    position: absolute;
    left: 0;
    top: 50%;
    height: 100%;
    width: 100%;
    text-align: center;
}

.flightScore {
    font-size: 1.5em;
    line-height: 1em;
    border: 2px solid #f3f7fa;
    margin-left: -0.8em;
    padding: 1em;
}

.flightScore .content {
    margin-top: -0.5em; /* Note, this must be half the font size */
}

.button-save-cancel {
    margin-bottom: 5em;
    margin-top: 1em;
}

.answer-btn {
    white-space: normal;
    border-spacing: 0 2em;
    font-size: 1.2em;
    background: $white;
    color: #2f85c8;
    display: table-cell;
    padding: 0.7em 0 0.7em 0;
}

.answer-btn-selected {
    background: #2f85c8;
    color: $white;
}

.answer-btn:focus {
    color: $white;
}

.btn-group {
    display: table;
}

.assessment-question {
    color: #474d5c;
    text-align: center;
}

.question-note {
    font-size: 0.9em;
}

.assessment-tip {
    background-color: #254152;
    color: $white;
    overflow: hidden;
}

.close {
    color: $white;
    margin-right: 1em;
}

.buttons {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.buttons button {
    width: 100%;
    margin-right: auto;
}

.navbar-brand .img-responsive {
    margin: 0 auto;
}

.answered-text {
    font-size: 20px;
    text-align: center;
    color: $white;
    @include ff($family-cond, $medium);
}

.heading-icon {
    height: 24px;
    width: 24px;
    display: block;
}

//switch styles
.switch-label {
    @include ff($family-cond, $reg);
    font-size: 14px;
    padding: 3px 0 0 6px;
    display: inline-block;
}
.switch {
    position: relative;
    display: inline-block !important;
    width: 40px;
    height: 22px;
    input {
        display: none;
        &:checked + .slider {
            background-color: $light-blue-primary;
        }
        &:focus + .slider {
            @include box-shadow(0, 0, 1px, 0, $light-blue-primary);
        }
        &:checked + .slider:before {
            -webkit-transform: translateX(18px);
            -ms-transform: translateX(18px);
            transform: translateX(18px);
        }
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $blue-shade-4;
        -webkit-transition: 0.4s;
        transition: 0.4s;

        @media (prefers-color-scheme: dark) {
            background-color: $neutral-975;
        }

        &::before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 3px;
            bottom: 3px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;

            @media (prefers-color-scheme: dark) {
                background-color: $neutral-100;
            }
        }
        &.round {
            border-radius: 24px;
            &:before {
                border-radius: 50%;
            }
        }
    }
}

.asset-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    @include ff($family-cond, $reg);
    li {
        @include flex();
        justify-content: space-between;
        align-items: center;
        color: $blue-shade-6;
        padding: 20px 0;
        cursor: pointer;
        margin: 0;
        img {
            margin: 0;
            cursor: pointer;
        }
    }
}
.onboarding {
    .asset-list {
        padding-top: 20px;
        li {
            margin-bottom: 20px;
        }
    }
    .asset-list.pilots li {
        border: none;
        @include radius(4px);
        padding: 15px 20px;
        background: #0f6aa2;
        @include box-shadow(0, 0, 0, 0, transparent);
        .pilot-email,
        .name,
        .status {
            color: white;
        }
        .status {
            margin-right: 0;
        }
    }
    .asset-list.aircrafts li {
        border: none;
        @include radius(4px);
        padding: 15px 20px;
        background: #0f6aa2;
        @include box-shadow(0, 0, 0, 0, transparent);
        span {
            color: white;
        }
    }
}
.wrap.pilots.licenses {
    .asset-list li {
        cursor: default;
    }
}

.border {
    @extend %border;
}
.container {
    .row {
        @include flex();
        justify-content: space-between;
        align-items: stretch;
        flex-direction: column;
        @include bp(browser) {
            flex-direction: row;
        }
        .col {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
    }
}
.content-wrap .header-title {
    display: -webkit-flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding-bottom: 1rem;
    min-height: 55px;
    @include bp(browser) {
        padding-bottom: 1.5rem;
    }
    border-bottom: 1px solid $blue-shade-2;

    @media (prefers-color-scheme: dark) {
        border-bottom: 1px solid $neutral-850;
    }

    h2 {
        margin: 0;
        text-align: left;
        color: $content-tone-1;
        @include ff($family-cond, $bold);
        font-size: 20px;
        @include flex();
        flex-basis: auto;
        align-items: center;
        // @include bp(browser) {
        //   font-size: 22px;
        // }

        @media (prefers-color-scheme: dark) {
            color: $content-tone-4;
        }
    }
    p {
        @include ff($family-cond, $reg);
        text-align: left;
        margin: 5px 0 0;
        line-height: 18px;
        font-size: 14px;
        color: $blue-shade-6;

        @media (prefers-color-scheme: dark) {
            color: $content-tone-3;
        }
    }
    a {
        @extend %btn-primary-sm;
    }
    a.secondary {
        @extend %btn-secondary-sm;
    }
    &.center {
        h2,
        p {
            text-align: center;
        }
    }
    .checkbox-container {
        min-width: 90px;
    }
}
.content-wrap {
    background: $white;
    @include radius(6px);
    @include box-shadow(0, 0, 15px, 0, #0000001a);
    padding: 1rem;
    margin-bottom: 1.25rem;
    width: 100%;

    @media (prefers-color-scheme: dark) {
        background: $neutral-900;
    }

    @include bp(browser) {
        margin-bottom: 1.5rem;
        padding: 1rem;
    }
    h6 {
        @include ff($family-cond, $medium);
        color: $content-tone-2;
        margin: 0;

        @media (prefers-color-scheme: dark) {
            color: $content-tone-3;
        }
    }
    .details {
        border-top: 1px solid $blue-shade-1;

        @media (prefers-color-scheme: dark) {
            border-top: 1px solid $neutral-850;
        }
        > div {
            text-align: left;
        }
    }
    .form-wrap {
        margin: 30px 0 25px;
        @include bp(tablet) {
            margin: 30px 0;
        }

        a {
            color: $light-blue-primary;
            text-decoration: none;
            cursor: pointer;
            font-size: 14px;
        }
    }
    p {
        @include ff($family-cond, $reg);
        font-size: 14px;
        margin: 0 0 10px;
        text-align: center;
        color: $blue-shade-6;
        line-height: 20px;

        @media (prefers-color-scheme: dark) {
            color: $content-tone-3;
        }

        &.small {
            font-size: 12px;
            line-height: 18px;
        }
    }
    p.legal {
        line-height: 20px;
        margin: 20px 0;
    }
    p.description.text-dark {
        color: $blue-shade-6;

        @media (prefers-color-scheme: dark) {
            color: $content-tone-3;
        }
    }

    .card-controls {
        margin: 30px 0 0;
        @include flex();
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include bp(browser) {
            flex-direction: row;
            justify-content: space-between;
        }
        .row {
            @include flex();
            flex-direction: column;
            justify-content: flex-start;
            flex-basis: 100%;
            width: 100%;
            + .row {
                justify-content: flex-end;
            }
            @include bp(browser) {
                order: 2;
                flex-direction: row;
                justify-content: flex-end;
                + .row {
                    flex-basis: 50%;
                    order: 1;
                    justify-content: flex-start;
                }
            }
        }
        button {
            width: 100%;
            @include bp(browser) {
                max-width: 180px;
                min-width: 130px;
                order: 2;
                margin-left: 20px;
            }
            @include bp(med-browser) {
                margin-left: 30px;
            }
            + button {
                margin-top: 20px;
                margin-left: 0;
                @include bp(browser) {
                    margin-top: 0;
                    order: 1;
                }
            }
        }
        a.link {
            @include flex();
            justify-content: flex-start;
            align-items: center;
            margin: 20px 0 0;
            @include bp(browser) {
                margin: 0;
            }
        }
    }
}

.footer-links {
    @extend %wrapper;
    @include flex();
    justify-content: center;
    // margin-bottom: 20px;
    flex-direction: row;
    text-align: center;
    align-items: center;
    margin-top: 30px;
    > div {
        @include flex();
        flex-direction: row;
        justify-content: flex-start;
    }
    p {
        color: $blue-shade-5;
        margin: 0 10px 0 0;
        text-align: center;
    }
    a {
        color: $light-blue-primary;
        text-decoration: none;
        cursor: pointer;
        margin: 0;
    }
    p,
    a {
        @include ff($family-cond, $reg);
        font-size: 14px;
    }
    // &.horizontal {
    //     flex-direction: row;
    //     & a {
    //       margin-top: 20px;
    //     }
    // }
}

///////
// app header + back button
///////

.active-filter {
    @include radius(8px);
    height: 8px;
    width: 8px;
    background-color: $moderate;
    @include bp(browser) {
        display: none;
    }
}

.actions {
    @include flex();
    flex-direction: row;
    a {
        height: 24px;
        width: 24px;
        cursor: pointer;
        @include flex();
        justify-content: center;
        align-items: center;
        span {
            display: none;
        }
        @include bp(tablet) {
            height: auto;
            width: auto;
            @include ff($family-cond, $bold);
            // font-size: 14px;
            // text-transform: uppercase;
            color: $blue-shade-5;
            @include radius(3px);
            border: none;
            text-decoration: none;
            @include flex();
            justify-content: center;
            align-items: center;
            span {
                display: block;
                color: $blue-shade-6;
            }
        }
        @include bp(browser) {
            color: $blue-shade-5;
            &:hover {
                background: none;
            }
        }
    }
    &.left {
        min-width: 75px;
        justify-content: flex-start;
        gap: 5px;
    }
    &.right {
        min-width: 75px;
        align-items: center;
        justify-content: flex-end;
        .button {
            @extend %btn-primary-sm;
            text-transform: none;
            gap: 5px;
            height: 34px;
            padding: 5px 15px !important;
        }
        .secondary-button {
            @include flex();
            @include ff($family-cond, 700);
            align-items: center;
            gap: 3px;
            font-size: 14px;
            color: $blue-primary;
            height: 34px;
            fill: $blue-primary;
        }
    }
    .secondary-button {
        cursor: pointer;
    }
}
.go-back {
    #go-back-arrow {
        fill: $blue-shade-5;
    }
    .go-back-icon {
        @include bp(tablet) {
            width: 20px;
            height: 12px;
        }
        @include bp(browser) {
            width: 20px;
            height: 12px;
            margin-left: 4px;
        }
    }
    &.onboarding {
        position: absolute;
        z-index: 15;
        overflow: visible;
        top: 0;
        left: 0;
        height: 30px;
        margin: 0 auto -30px;
        @include bp(browser) {
            left: -7px;
        }
        a {
            color: $blue-shade-5;
            top: 14px;
            left: 11px;
            #go-back-arrow {
                fill: $blue-shade-5;
            }
        }
    }
}
.edit-flight-details {
    cursor: pointer;
    @include flex();
    justify-content: center;
    align-items: center;
    @include ff($family-cond, $bold);
    font-size: 12px;
    text-transform: uppercase;
    color: $blue-shade-5;
    span {
        display: none;
        color: $blue-shade-6;
        padding-right: 5px;
    }
    @include bp(tablet) {
        span {
            display: block;
        }
    }
}
.helptip {
    margin-top: 10px;
    p {
        text-align: left;
        font-size: 12px;
        line-height: 16px;
    }
}

ul.selection-list {
    margin: 0;
    padding: 0;
    width: auto;
    .option {
        @include flex();
        flex-direction: row;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        padding: 20px 0;
        border-top: 1px solid $blue-shade-2;
        width: fit-content;
        &:nth-child(1) {
            border-top: none;
        }
        cursor: pointer;
        @include bp(tablet) {
            padding: 20px 0;
        }
        img {
            margin: 0 10px 0 0;
        }
        h6 {
            @include ff($family-cond, $reg);
            color: $content-tone-2;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 0;

            @media (prefers-color-scheme: dark) {
                color: $content-tone-3;
            }
        }
        &:last-child {
            padding-bottom: 0;
        }
        &.selected,
        &:hover {
            h6 {
                color: $light-blue-primary;
            }
        }
    }
}
.checkbox-container {
    h6 {
        @include ff($family-cond, $reg);
        color: $content-tone-1;
        font-size: 14px;
        line-height: 22px;
        margin: 0;

        @media (prefers-color-scheme: dark) {
            color: $content-tone-4;
        }
    }
}

#ngb-live {
    display: none;
}

//promo code styles
.promo-code {
    a {
        text-align: left;
        margin-bottom: 20px;
    }
    .promo-code-input-wrap {
        @include flex();
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 20px;
        .input-code {
            @include flex();
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            div {
                flex-basis: 100%;
                flex: 2;
                label {
                    margin-top: 20px;
                }
            }
            button {
                flex-basis: 100%;
                flex: 1;
                margin-left: 15px;
                height: 40px;
            }
        }
    }
}
.validpromo {
    background: $blue-shade-1;
    border: 1px solid $blue-shade-4;
    margin: 30px 0;
    padding: 10px;
    @include ff($family-cond, $reg);
    @include radius(4px);
    @include flex();
    flex-direction: row;
    justify-content: space-between;

    @media (prefers-color-scheme: dark) {
        background: $neutral-850;
        border: none;
    }

    .promo-details {
        width: 80%;
        @include flex();
        flex-direction: row;
        justify-content: flex-start;
        svg {
            margin-right: 10px;
            margin-top: 10px;
            fill: $content-tone-1;
            @media (prefers-color-scheme: dark) {
                fill: $content-tone-4;
            }
        }
    }
    p {
        margin: 0;
        text-align: left;
    }
    img {
        margin: 0;
        cursor: pointer;
    }
}
//customize assessment section
button.add-new {
    @include box-shadow(0, 0, 4px, 0, $blue-shade-3);
    font-size: 14px;
}
//bulk import feature
#bulk-invite-feature {
    h2 {
        text-align: left;
        font-size: 16px;
        margin-top: 25px;
        margin-bottom: 15px;
        color: $blue-shade-7;
        font-weight: $medium;
    }
    .help-text {
        margin-bottom: 30px;
        p {
            text-align: left;
            span {
                font-style: italic;
                font-size: 11px;
            }
        }
        span.label {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: $bold;
            display: block;
            color: $blue-shade-7;
        }
        a.link {
            margin-top: 10px;
            text-align: left;
        }
    }
    .btn-primary {
        margin-bottom: 0;
        text-transform: capitalize;
    }
    input[type="file"] {
        display: none;
    }
}

//dropdown for icao
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 30;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    margin: 0 0 20px;
    &.show {
        display: block;
    }

    .dropdown-item {
        display: block;
        width: 100%;
        padding: 5px 10px;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;

        &.active,
        &:active {
            color: #fff;
            text-decoration: none;
            background-color: #007bff;
        }

        .ngb-highlight {
            font-weight: 700;
        }
    }
}

//setup company
.onboarding-screen {
    .step-content {
        @include flex();
        flex-direction: column;
        margin: 0 0 20px;
        position: relative;
        @include bp(tablet) {
            margin: 0 auto 30px;
            width: 100%;
            max-width: 420px;
        }
        .issue-notice {
            color: $white;
        }
    }
}

//account edit
.account-edit {
    label {
        color: $blue-shade-4;
    }
    .step-content {
        @include flex();
        flex-direction: column;
        margin: 0;
        position: relative;
        @include bp(tablet) {
            margin: 0 auto;
            width: 100%;
        }
        .issue-notice {
            color: $white;
        }
    }
}

.new-frat-screen {
    .dropdown-menu {
        @extend .dropdown-menu;
        @include bp(browser) {
            margin: 0 auto 30px;
            max-width: $frat-width;
        }
    }
}
.input-group {
    position: relative;
}

////DEFAULT RADIO/CHECK STYLES
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type="checkbox"],
    input[type="radio"] {
        --active: #1987cb;
        --active-inner: #fff;
        --focus: 1px rgba(51, 75, 148, 0.3);
        --border: #91a0b5;
        --border-hover: #6d808d;
        --background: #fff;
        --disabled: #f5f7fa;
        --switchbg: #f3f7fa;
        --disabled-inner: #dfe7f2;
        --active-dark: #98a6bc;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 18px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition:
            background 0.3s,
            border-color 0.3s,
            box-shadow 0.2s;

        @media (prefers-color-scheme: dark) {
            --active: #479fd5;
            --disabled: #5c6370;
            --disabled-inner: #5c6370;
            --border: #91a0b5;
        }

        &:after {
            content: "";
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            transition:
                transform var(--d-t, 0.3s) var(--d-t-e, ease),
                opacity var(--d-o, 0.2s);
        }
        &:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: 0.3s;
            --d-t: 0.6s;
            --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
        }
        &:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: 1;
            &:checked {
                --b: var(--disabled-inner);
                --bc: var(--border);
                &:after {
                    border-color: var(--border);
                }
            }
            & + label {
                cursor: not-allowed;
            }
        }
        &:hover {
            &:not(:checked) {
                &:not(:disabled) {
                    --bc: var(--active);
                }
            }
        }
        &:focus {
            box-shadow: 0 0 0 var(--focus);
        }
        &:not(.switch) {
            width: 18px;
            &:after {
                opacity: var(--o, 0);
            }
            &:checked {
                --o: 1;
            }
        }
        & + label {
            font-size: 14px;
            line-height: 18px;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin-left: 5px;
        }
    }
    input[type="checkbox"] {
        --disabled-inner: #f5f7fa;

        @media (prefers-color-scheme: dark) {
            --disabled-inner: #5c6370;
        }

        &:not(.switch) {
            @media (prefers-color-scheme: dark) {
                --background: #393c42;
            }
            border-radius: 2px;
            &:after {
                width: 5px;
                height: 9px;
                border: 2px solid var(--active-inner);
                border-top: 0;
                border-left: 0;
                left: 6px;
                top: 3px;
                transform: rotate(var(--r, 20deg));
            }
            &:checked {
                --r: 43deg;
            }
        }
        &.switch {
            --active-inner: #5c6370;
            --border: #91a0b5;
            width: 38px;
            height: 21px;
            border-radius: 11px;
            --bc: var(--border);
            --b: var(--disabled);

            &:hover {
                --active-inner: #479fd5;
            }

            @media (prefers-color-scheme: dark) {
                --active-inner: #91a0b5;
                --border: #91a0b5;
                --b: #1a1a1c;
            }

            &:after {
                left: 2px;
                top: 2px;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background: var(--ab, var(--active-inner));
                transform: translateX(var(--x, 0));
            }
            &:checked {
                --active-inner: white;
                --ab: var(--active-inner);
                --x: 17px;
                --b: var(--active);
                --bc: var(--active);
                &:disabled,
                &.disabled {
                    --bc: var(--active-dark);
                    --b: var(--active-dark);
                }
            }
            &:disabled,
            &.disabled {
                &:after {
                    background: var(--active-dark);
                }
                &:checked {
                    &:after {
                        background: var(--border-hover);
                    }
                }
            }
            + label {
                line-height: 22px;
            }
        }
    }
    input[type="radio"] {
        border-radius: 50%;
        &:after {
            width: 18px;
            height: 18px;
            left: -1px;
            top: -1px;
            border-radius: 50%;
            background: var(--active-inner);
            opacity: 0;
            transform: scale(var(--s, 0.7));
        }
        &:checked {
            --s: 0.5;
        }
        &:disabled {
            &:checked {
                &:after {
                    background: #91a0b5;
                }
            }
        }
    }
}

.radio-group {
    margin: 10px 0 0;
    border: 1px solid $blue-shade-2;
    background: white;
    @include radius(4px);
    @include flex();
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    height: 40px;

    @media (prefers-color-scheme: dark) {
        border: none;
        background: transparent;
    }

    .selection {
        @include flex();
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    input[type="radio"] {
        min-width: 18px;
        margin: 12px;
    }
    label {
        @include ff($family-cond, $medium);
        color: $blue-shade-6;
        font-size: 14px;
        display: inline-block;
        padding: 12px 0;
        margin: 0;
        width: 100%;
        cursor: pointer;

        @media (prefers-color-scheme: dark) {
            color: $content-tone-3;
        }
    }
}

.check-wrap {
    border-top: 1px solid $blue-shade-2;
    border-bottom: 1px solid $blue-shade-2;
    label {
        font-weight: $reg;
        padding: 1rem 5px;
        @include flex;
        align-items: center;
        cursor: pointer;
        margin: 0;
        input {
            margin-right: 15px;
        }
    }
}
.assessment-association {
    h3 {
        margin-bottom: 5px;
    }
    p {
        text-align: left;
    }
    .header p {
        margin-bottom: 0;
    }
    .content {
        margin: 20px 0;
        .grid > div {
            margin-top: -1px;
        }
    }
}

.policies-association {
    .header {
        display: grid;
        grid-template-rows: min-content min-content;
        grid-template-columns: auto max-content;
        align-items: center;

        .manage-button-desktop {
            border: none;
            background: none;
            grid-row: span 2 / span 2;
        }

        .manage-button-mobile {
            height: 30px;
            width: 30px;
        }
    }
    h3 {
        margin-top: 0;
        margin-bottom: 5px;
    }
    p {
        text-align: left;
    }
    .header p {
        margin-bottom: 0;
    }
    .content {
        @include flex();
        flex-direction: column;
        gap: 20px;
        margin: 20px 0;
    }
}

.mat-bottom-sheet-container {
    &.full-width {
        min-width: 100vw !important;
        @include radius(0px);
        padding: 0;
    }
    .wrapper {
        @extend %wrapper;
    }
    .header,
    .controls {
        @include flex();
        flex-direction: row;
        justify-content: space-between;
    }
    .header {
        margin-top: 15px;
        h4 {
            margin: 10px 0;
        }
        img {
            margin: 0;
            cursor: pointer;
        }
    }
    .controls {
        margin: 30px 0;
    }
}

.filter-button {
    @include flex();
    @include radius(5px);
    @include ff($family-cond, 700);
    font-size: 12px;
    align-items: center;
    gap: 3px;
    padding: 6px 10px;
    background: white;
    border: 1px solid $neutral-200;
    cursor: pointer;
    fill: $content-tone-1;

    @media (prefers-color-scheme: dark) {
        background: $neutral-900;
        border: 1px solid $neutral-850;
        fill: $content-tone-4;
    }
}

//Cards

.item-wrapper {
    display: none;
    background-color: $white;

    @media (prefers-color-scheme: dark) {
        background-color: $neutral-900;
    }

    @include radius(6px);
    @include box-shadow(0, 0, 10px, 0, rgba(0, 0, 0, 0.12));
    padding: 15px;
    // height: 62px;
    align-items: center;
    @include bp(med-browser) {
        @include grid(1fr);
        gap: 10px;
        @include ff($family-cond, $medium);
        width: 100%;
    }
}

.item-wrapper-mobile {
    @include flex();
    flex-direction: column;
    background-color: $white;

    @media (prefers-color-scheme: dark) {
        background-color: $neutral-900;
    }

    @include radius(6px);
    @include box-shadow(0, 0, 10px, 0, rgba(0, 0, 0, 0.12));

    @include bp(med-browser) {
        display: none;
    }

    .header {
        @include flex();
        justify-content: space-between;
        padding: 16px 18px;
        @include radii(6px, 6px, 0, 0);
    }

    .content {
        @include flex();
        flex-direction: column;
        gap: 20px;
        padding: 18px;
    }

    .content-grid {
        @include grid(1fr 1fr);
        gap: 20px;
    }

    .content-field {
        @include flex();
        flex-direction: column;
        gap: 4px;

        &.title {
            grid-column: span 2;

            span {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        span {
            white-space: break-spaces;
        }
    }

    .field-header {
        @include ff($family-cond, 700);
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: $content-tone-1;

        @media (prefers-color-scheme: dark) {
            color: $content-tone-4;
        }
    }
}

.field,
.content-field {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $content-tone-2;
    margin-bottom: 0;

    @media (prefers-color-scheme: dark) {
        color: $content-tone-3;
    }

    span:empty:after {
        content: "––";
        color: $gray-shade-4;
    }
}

.status-field {
    @include issue-status();
}

.risk {
    @include ff($family-cond, 700);
    font-size: 24px;
    line-height: 28px;
    text-align: left;

    &.mild {
        color: $mild;
    }

    &.moderate {
        color: $moderate;
    }

    &.severe {
        color: $severe;
    }
}

.button-container {
    @include flex();
    gap: 10px;
    justify-content: flex-end;

    button {
        height: 40px;
        @include bp(browser) {
            height: 30px;
        }
        padding: 0 23px;

        :host-context(.mobile) & {
            width: 100%;
        }
    }
}

.mat-drawer-backdrop.mat-drawer-shown,
.cdk-overlay-dark-backdrop {
    backdrop-filter: blur(3px);
}

.mat-drawer-container {
    // background: red;
    background-color: $blue-shade-0;
}
.mat-drawer {
    background-color: $neutral-200 !important;

    @media (prefers-color-scheme: dark) {
        background-color: $neutral-950 !important;
    }
}
.mat-expansion-panel {
    background: none !important;

    @media (prefers-color-scheme: dark) {
        border-bottom: none !important;
    }
}

.mat-expansion-panel-header-title {
    color: $content-tone-1 !important;
    fill: $content-tone-1;

    @media (prefers-color-scheme: dark) {
        color: $content-tone-4 !important;
        fill: $content-tone-4;
    }
}

.file-input {
    display: none;
}

//Charts
:root {
    --neutral-100: #f5f7fa;
    --neutral-200: #eceff4;
    --neutral-500: #c4cfde;
    --neutral-700: #79879a;
    --neutral-800: #5c6370;
    --neutral-850: #4c5057;

    --content-tone-2: var(--neutral-800);
    --content-tone-3: var(--neutral-500);

    --chart-text: var(--content-tone-2);
    --chart-divider: var(--neutral-100);
    --chart-line: var(--neutral-800);
    --chart-line-alt: var(--neutral-700);

    @media (prefers-color-scheme: dark) {
        --chart-text: var(--content-tone-3);
        --chart-divider: var(--neutral-850);
        --chart-line: var(--neutral-200);
        --chart-line-alt: var(--neutral-500);
    }
}

//Dark mode images
@media (prefers-color-scheme: dark) {
    img#img-risk-assessment {
        content: url("/assets/img/welcome-trial/risk-assessment-dark.svg");
    }
    img#img-hazard-report {
        content: url("/assets/img/welcome-trial/hazard-report-dark.svg");
    }
    img#img-risk-mitigation {
        content: url("/assets/img/welcome-trial/risk-mitigation-dark.svg");
    }
    img#img-safety-promotion {
        content: url("/assets/img/welcome-trial/safety-promotion-dark.svg");
    }
    #img-empty-objectives {
        content: url("/assets/img/empty_objectives-dark.svg");
    }
    #img-empty-tasks {
        content: url("/assets/img/empty-tasks-dark.svg");
    }
    #img-empty-forms {
        content: url("/assets/img/empty-forms-dark.svg");
    }
    #img-empty-insights {
        content: url("/assets/img/empty-insights-dark.svg");
    }
    #img-empty-issues {
        content: url("/assets/img/empty-issues-dark.svg");
    }
    #img-empty-members {
        content: url("/assets/img/empty-members-dark.svg");
    }
    #img-empty-promotions {
        content: url("/assets/img/empty-promotions-dark.svg");
    }
    #illustration-need-access {
        content: url("/assets/img/illustration_need-access-dark.svg");
    }
    #feature-blocker-safety-assurance {
        content: url("/assets/img/empty-weather-dark.svg");
    }
    #icon-mitigation-arrow {
        content: url("/assets/icons/icon_mitigation-arrow-dark.svg");
    }
    #icon-weather {
        content: url("/assets/icon/icon-weather-dark.svg");
    }
    #icon-notams {
        content: url("/assets/icon/icon-notams-dark.svg");
    }
}
