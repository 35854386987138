@mixin ff($fam, $weight: 400, $style: normal) {
    font-family: $fam;
    font-weight: $weight;
    font-style: $style;
}

%text-body-lg {
    font-size: 16px;
    line-height: 20px;
}
%text-body {
    font-size: 14px;
    line-height: 20px;
}
%text-body-sm {
    font-size: 12px;
    line-height: 16px;
}
%text-body-xs {
    font-size: 11px;
    line-height: 16px;
}
%text-body-xxs {
    font-size: 10px;
    line-height: 12px;
}

@mixin bp($point) {
    //for old mini phones
    $bp-mini: "(max-width: 320px)";

    //For anything specific to the mobile experience that should not be anywhere else
    $bp-phone: "(max-width: 375px)";

    //For anything under tablets screens - 0 - 576px
    $bp-below-tablet: "(max-width: 576px)";

    //For anything specific to tablets and below
    $bp-below-desktop: "(max-width: 768px)";

    //For anything specific to tablets or small screens - 577-768px
    $bp-tablet: "(min-width: 577px)";

    //For anything specific to larger screens - 769px and up
    $bp-browser: "(min-width: 769px)";

    //rare 1-off fixes for pixel breakpoints on tablet portrait and up - 768px and up
    $bp-tablet-horizontal: "(min-width: 768px)";

    //For anything specific to medium screens - 1024px and up
    $bp-med-browser: "(min-width: 1024px)";

    //For anything specific to larger screens - 1280px and up
    $bp-large-browser: "(min-width: 1280px)";

    @if $point == tablet-horizontal {
        @media #{$bp-tablet-horizontal} {
            @content;
        }
    } @else if $point == browser {
        @media #{$bp-browser} {
            @content;
        }
    } @else if $point == med-browser {
        @media #{$bp-med-browser} {
            @content;
        }
    } @else if $point == tablet {
        @media #{$bp-tablet} {
            @content;
        }
    } @else if $point == belowdesktop {
        @media #{$bp-below-desktop} {
            @content;
        }
    } @else if $point == belowtablet {
        @media #{$bp-below-tablet} {
            @content;
        }
    } @else if $point == phone {
        @media #{$bp-phone} {
            @content;
        }
    } @else if $point == mini {
        @media #{$bp-mini} {
            @content;
        }
    } @else if $point == large-browser {
        @media #{$bp-large-browser} {
            @content;
        }
    }
}
@mixin flex() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin grid($template) {
    display: grid;
    grid-template-columns: $template;
    gap: 5px;
    align-items: center;
}

@mixin radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
}

@mixin radii($topleft, $topright, $bottomright, $bottomleft) {
    border-top-left-radius: $topleft;
    border-top-right-radius: $topright;
    border-bottom-right-radius: $bottomright;
    border-bottom-left-radius: $bottomleft;
    -webkit-border-top-left-radius: $topleft;
    -webkit-border-top-right-radius: $topright;
    -webkit-border-bottom-right-radius: $bottomright;
    -webkit-border-bottom-left-radius: $bottomleft;
    -moz-border-radius-topleft: $topleft;
    -moz-border-radius-topright: $topright;
    -moz-border-radius-bottomright: $bottomright;
    -moz-border-radius-bottomleft: $bottomleft;
}

@mixin box-shadow(
    $horz: 0px,
    $vert: 0px,
    $blur: 0px,
    $radius: 4px,
    $color: #c5c5c5
) {
    -webkit-box-shadow: $horz $vert $blur $radius $color; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: $horz $vert $blur $radius $color; /* Firefox 3.5 - 3.6 */
    box-shadow: $horz $vert $blur $radius $color; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

%shownhide {
    .mobile {
        display: block;
    }
    .desktop {
        display: none;
    }
    @include bp(browser) {
        .mobile {
            display: none;
        }
        .desktop {
            display: block;
        }
    }
}

%btn {
    // @include bp(mini){
    //   height: 40px;
    // }
    height: 30px;
    // padding: 10px;
    @include ff($family-cond, $bold);
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    @include flex();
    align-items: center;
    justify-content: center;
    outline: none;
    @include radius(4px);
}
%btn-remove {
    @extend %btn;
    height: 30px;
    padding: 0 15px;
    color: $severe;
    border: 1px solid $severe;
    background-color: $white;

    @media (prefers-color-scheme: dark) {
        background-color: transparent;
    }

    &:hover {
        border: 1px solid $severe-dark;
        color: $severe-dark;
    }
}
%btn-primary {
    @extend %btn;
    color: $white;
    background-color: $light-blue-primary;
    &:hover {
        background: $light-blue-secondary;
    }
}
%btn-primary-sm {
    height: 35px;
    padding: 5px 10px;
    @include ff($family-cond, $bold);
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    @include flex();
    align-items: center;
    justify-content: center;
    outline: none;
    @include radius(4px);
    color: white;
    background-color: $light-blue-primary;
    //border: 1px solid $light-blue-primary;
    &:hover {
        background-color: $light-blue-secondary;
    }
    &:disabled,
    &.disabled {
        background-color: #e8eef1;
        color: #c9d6de;
        pointer-events: none;

        @media (prefers-color-scheme: dark) {
            background-color: $neutral-900;
            color: $neutral-650;
        }

        img {
            filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(310deg)
                brightness(100%) contrast(100%) opacity(0.1);
        }
    }
}

%btn-primary-dark {
    @extend %btn;
    color: $white;
    background-color: $dark-blue-primary;
    &:hover {
        background: $dark-blue-secondary;
    }
}

%btn-primary-outline {
    @extend %btn;
    color: $white;
    border: 1px solid white;
    background: transparent;
    &:hover {
        background: $light-blue-primary;
    }
}

%btn-secondary {
    @extend %btn;
    color: $light-blue-primary;
    background-color: transparent;
    border: 1px solid $light-blue-primary;
    fill: $content-tone-1;

    @media (prefers-color-scheme: dark) {
        fill: $content-tone-4;
    }

    &:hover {
        background-color: transparent;
    }
}
%btn-secondary-sm {
    // height: 30px;
    padding: 5px 10px;
    @include ff($family-cond, $bold);
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    @include flex();
    align-items: center;
    justify-content: center;
    outline: none;
    @include radius(4px);
    color: $light-blue-primary;
    background-color: transparent;
    border: 1px solid $light-blue-primary;
    &:hover {
        background-color: transparent;
    }
}

%btn-tertiary {
    @extend %btn;
    color: $white;
    background-color: $blue-shade-4;
    &:hover {
        background-color: $blue-shade-5;
    }
}

%btn-quaternary {
    @extend %btn;
    color: $light-blue-primary;
    background-color: $white;
    border: 1px solid $blue-shade-3;

    @media (prefers-color-scheme: dark) {
        border: 1px solid $blue-primary;
        background-color: transparent;
    }

    &:hover {
        background-color: $blue-shade-1;

        @media (prefers-color-scheme: dark) {
            background-color: transparent;
        }
    }
}

%btn-disabled {
    @extend %btn;
    color: $blue-shade-4;
    background-color: $blue-shade-2;
    border: none;
    cursor: not-allowed;

    @media (prefers-color-scheme: dark) {
        background-color: $neutral-850;
        color: $neutral-650;
    }

    &:hover {
        background-color: $blue-shade-2;

        @media (prefers-color-scheme: dark) {
            background-color: $neutral-850;
        }
    }
}

%btn-disabled-sm {
    background-color: $blue-shade-2;
    cursor: not-allowed;

    @media (prefers-color-scheme: dark) {
        background-color: $neutral-850;
        color: $neutral-650;
    }

    &:hover {
        background-color: $blue-shade-2;

        @media (prefers-color-scheme: dark) {
            background-color: $neutral-850;
        }
    }
}

%btn-disabled-light {
    @extend %btn;
    color: $blue-shade-3;
    background-color: $blue-shade-2;
    cursor: not-allowed;

    @media (prefers-color-scheme: dark) {
        background-color: $neutral-850;
        color: $neutral-650;
    }

    &:hover {
        background-color: $blue-shade-2;

        @media (prefers-color-scheme: dark) {
            background-color: $neutral-850;
        }
    }
}

%border {
    border-top: 1px solid $blue-shade-2;
    border-bottom: 1px solid $white;
    margin-top: -8px;
}

%wrapper {
    margin: 0 20px;
    @include bp(browser) {
        margin: 0 auto;
        width: $frat-width;
    }
}

%wrapper2 {
    @include flex();
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    @include bp(tablet) {
        max-width: 728px;
    }
    @include bp(browser) {
        max-width: 1024px;
    }
}

%overlay-buttons {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 11;
    .more-buttons {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        //left: calc(50vw - 29px);
        @include ff($family-cond, $bold);
        font-size: 14px;
        text-transform: uppercase;
        //font-stretch: normal;
        //line-height: normal;
        //letter-spacing: normal;
        //text-align: center;
        list-style-type: none;
        color: $white;
        li {
            @include flex();
            flex-direction: row;
            text-align: right;
            align-items: center;
            height: 75px;
            cursor: pointer;
            span {
                flex: 1;
            }
            img {
                margin: 0 20px 0 15px;
            }
        }
        .cancel-overlay {
            margin: 20px 20px 20px 0;
            cursor: pointer;
            float: right;
            width: 50px;
            height: 50px;
        }
    }
}

@mixin animateReveal() {
    animation: show 1s ease 0s 1;
    animation-delay:
        5s,
        hide 1s ease 0s 1;
    @keyframes show {
        0% {
            margin-top: -50px;
            opacity: 0;
        }
        100% {
            margin-top: 0;
            opacity: 1;
        }
    }
    @keyframes hide {
        0% {
            margin-top: 0;
            opacity: 1;
        }
        100% {
            margin-top: -50px;
            opacity: 0;
        }
    }
}

@mixin scrollbars(
    $size,
    $foreground-color,
    $background-color: mix($foreground-color, white, 50%)
) {
    // For Google Chrome
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }
    ​ &::-webkit-scrollbar-thumb {
        background: $foreground-color;
        box-shadow: inset 0 0 0 1.5px $background-color;
        border-radius: 0.25rem;
    }
    ​ &::-webkit-scrollbar-track {
        background: $background-color;
        border-radius: 0.25rem;
    }
    ​
  // For Internet Explorer
  & {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}

@mixin label-container() {
    display: flex;
    justify-content: space-between;
    margin: 0 0 5px;

    label {
        display: inline;
        margin: 0;
    }

    a {
        margin: 0;

        &.link {
            margin: 0;
        }
    }
}

@mixin details-card() {
    @include flex();
    flex-direction: column;
    gap: 20px;

    .details-header {
        @include flex();
        justify-content: space-between;
        align-items: center;
        height: 30px;
    }

    .container-label {
        margin: 0;
        color: $blue-shade-7;
        @include ff($family-cond, 700);
        font-size: 20px;
        text-align: left;
        text-transform: capitalize;
    }

    .button-container {
        @include flex();
        gap: 10px;

        .btn-secondary {
            background: none;
            height: 30px;
        }
    }

    .details-card {
        width: 100%;
        @include radius(6px);
        @include box-shadow(0, 0, 5px, 0, rgba(0, 0, 0, 0.12));
        background: white;
        font-size: 14px;

        @media (prefers-color-scheme: dark) {
            background: $neutral-900;
        }
    }
}

@mixin issue-status() {
    @include flex();
    align-items: center;
    padding: 3px 8px;
    @include ff($family-cond, 700);
    font-size: 12px;
    line-height: 17px;
    text-align: left;
    @include radius(4px);
    text-transform: capitalize;
    width: fit-content;
    height: fit-content;

    &.active,
    &.open {
        background-color: $blue-100;
        color: $blue-700;

        @media (prefers-color-scheme: dark) {
            background-color: $blue-800;
            color: $blue-200;
        }
    }

    &.sent,
    &.incomplete {
        background-color: $blue-100;
        color: $neutral-950;

        @media (prefers-color-scheme: dark) {
            background-color: $neutral-750;
            color: $neutral-200;
        }
    }

    &.resolved,
    &.requesting,
    &.completed {
        background-color: $green-100;
        color: $green-800;

        @media (prefers-color-scheme: dark) {
            background-color: $green-900;
            color: $green-200;
        }
    }

    &.archived {
        background-color: $neutral-150;
        color: $neutral-800;

        @media (prefers-color-scheme: dark) {
            background-color: $neutral-850;
            color: $neutral-200;
        }
    }

    &.overdue,
    &.denied {
        background-color: $red-100;
        color: $red-700;

        @media (prefers-color-scheme: dark) {
            background-color: $red-800;
            color: $red-200;
        }
    }
}

@mixin bottom-sheet() {
    .overlay {
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.7);
        position: fixed;
        z-index: 20;
        top: 0;
        left: 0;
    }

    .more-options {
        background: white;
        @include box-shadow(0px, -2px, 4px, 0, #000000);
        position: fixed;
        z-index: 21;
        bottom: 0;
        width: 100%;
        .wrapper {
            @extend %wrapper;
        }
    }

    .section-header {
        @include flex();
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        h3 {
            color: $dark-blue-secondary;
            margin: 10px 0;
        }
        img {
            cursor: pointer;
            margin: 0;
        }
    }
    .menu-actions {
        margin: 0 0 20px;
        @include bp(tablet) {
            margin: 0 0 40px;
        }
        padding: 0;
        list-style-type: none;
        li {
            border-top: 1px solid $blue-shade-1;
            // height: 60px;
            &:first-child {
                border-top: none;
            }
            button {
                display: grid;
                grid-template-columns: 45px auto;
                gap: 10px;
                cursor: pointer;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                @include radius(4px);
                padding: 10px;
                margin: 10px 0;
                width: 100%;
                background: none;
                height: auto;
                &:hover {
                    background: $blue-shade-0;
                }
                &:disabled {
                    background: $blue-shade-0;
                    cursor: not-allowed;
                    p {
                        font-style: italic;
                        color: $blue-shade-5;
                    }
                    img {
                        opacity: 0.4;
                    }
                }
            }
            &:last-child {
                button {
                    margin-bottom: 0;
                }
            }
            img {
                margin: 0 auto;
            }
            h6 {
                padding: 0;
                margin: 0;
                @include ff($family-cond, $bold);
                font-size: 14px;
                color: $dark-blue-secondary;
            }
        }
    }
}

@mixin table-filters() {
    margin: -10px -20px 20px;
    padding: 0 20px 10px;
    @include flex();
    flex-flow: nowrap;
    overflow-x: scroll;
    @include scrollbars(0, $blue-shade-7, $blue-shade-7);
    border-bottom: 1px solid $neutral-300;
    gap: 10px;

    @media (prefers-color-scheme: dark) {
        border-bottom: 1px solid $neutral-900;
    }

    @include bp(browser) {
        border-bottom: none;
        margin-bottom: 10px;
    }
    @include bp(med-browser) {
        flex-flow: wrap;
        border: none;
        margin: -5px 0 0;
        padding: 0;
    }
    .filter-button {
        color: $content-tone-1;
        width: max-content;

        @media (prefers-color-scheme: dark) {
            color: $content-tone-4;
        }
    }
}

@mixin normal-header-spacing() {
    top: $mobile-expanded-header-height;
    @include bp(browser) {
        top: $header-height;
    }
}
@mixin expanded-header-spacing() {
    top: $mobile-expanded-header-height;
    @include bp(browser) {
        top: $expanded-header-height;
    }
}

@mixin form-editor-expanded-header-spacing() {
    // TODO:responsive directive does not work for form editor?
    top: $form-editor-mobile-expanded-header-height;

    @include bp(browser) {
        top: $expanded-header-height;
    }
}

@mixin border() {
    border-color: $neutral-300;
    @media (prefers-color-scheme: dark) {
        border-color: $neutral-850;
    }
}
