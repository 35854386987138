.p-paginator {
    justify-content: flex-start !important;
    background: none; // $paginatorBg;
    color: $paginatorTextColor;
    border: $paginatorBorder;
    border-width: $paginatorBorderWidth;
    padding: 0;
    border-radius: $borderRadius;

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
        background-color: $paginatorElementBg;
        border: $paginatorElementBorder;
        color: $paginatorElementIconColor;
        width: 40px !important;
        height: 40px !important;
        margin: 4px;
        transition: $listItemTransition;
        border-radius: $paginatorElementBorderRadius;

        &:not(.p-disabled):not(.p-highlight):hover {
            background: $paginatorElementHoverBg;
            border-color: $paginatorElementHoverBorderColor;
            color: $paginatorElementIconHoverColor;

            @media (prefers-color-scheme: dark) {
                color: $content-tone-3;
                background: none;
            }
        }

        svg {
            height: 14px;
            width: 14px;
        }
    }

    .p-paginator-first {
        border-top-left-radius: $paginatorElementBorderRadius;
        border-bottom-left-radius: $paginatorElementBorderRadius;
    }

    .p-paginator-last {
        border-top-right-radius: $paginatorElementBorderRadius;
        border-bottom-right-radius: $paginatorElementBorderRadius;
    }

    .p-dropdown {
        margin-left: $inlineSpacing;
        margin-right: $inlineSpacing;
        height: $paginatorElementHeight;

        .p-dropdown-label {
            padding-right: 0;
        }
    }

    .p-paginator-page-input {
        margin-left: $inlineSpacing;
        margin-right: $inlineSpacing;

        .p-inputtext {
            max-width: $paginatorElementWidth;
        }
    }

    .p-paginator-current {
        background-color: $paginatorElementBg;
        border: $paginatorElementBorder;
        color: $paginatorElementIconColor;
        min-width: $paginatorElementWidth;
        height: $paginatorElementHeight;
        margin: $paginatorElementMargin;
        padding: 0 $inlineSpacing;
    }

    .p-paginator-pages {
        .p-paginator-page {
            background-color: $paginatorElementBg;
            border: $paginatorElementBorder;
            color: $paginatorElementIconColor;
            min-width: $paginatorElementWidth;
            height: $paginatorElementHeight;
            margin: $paginatorElementMargin;
            transition: $listItemTransition;
            border-radius: $paginatorElementBorderRadius;

            &.p-highlight {
                background: $highlightBg;
                border-color: $highlightBg;
                color: $highlightTextColor;
            }

            &:not(.p-highlight):hover {
                background: $paginatorElementHoverBg;
                border-color: $paginatorElementHoverBorderColor;
                color: $paginatorElementIconHoverColor;
            }
        }
    }

    .p-paginator-page-options {
        height: 48px;
        margin: 0 4px;

        .p-dropdown-label {
            display: flex;
            align-items: center;
            font-size: 16px;
        }

        .p-dropdown-trigger {
            width: fit-content;
            padding: 10px;
        }
    }
}

.p-dropdown-items-wrapper {
    @include scrollbars(0, white, white);
}
