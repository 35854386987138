@import "variables";
@import "mixins";
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-underline {
    display: none;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-label {
    color: initial;
    margin-top: 15px;
    padding-left: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label {
    color: initial;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0;
    margin: 0;
    border-top: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-label-wrapper {
    margin: 0;
    top: -16px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy .mat-form-field-label {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #6d808d;
    text-indent: 10px;
    text-transform: capitalize;
    &:placeholder-shown {
        text-transform: capitalize;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label {
    color: #6d808d !important;
}

.mat-mdc-button {
    padding: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
}

/* MAT MENU */
.mat-mdc-menu-panel {
    max-width: none !important;
    max-height: 50vh !important;
    @include scrollbars(0, 0, white);
    min-height: fit-content !important;

    @media (prefers-color-scheme: dark) {
        background-color: $neutral-900 !important;
    }
}

.mat-drawer-inner-container {
    @include scrollbars(0, 0, white);
}

:root {
    --mat-sidenav-content-background-color: $blue-shade-0;
}
