@import "variables";
@import "mixins";

quill-editor {
    @include radius(6px);
    box-shadow: 0 0 15px 0 #0000001a;
    background: white;

    @media (prefers-color-scheme: dark) {
        background: $neutral-950;
    }
}

.ql-editor.ql-blank::before {
    @media (prefers-color-scheme: dark) {
        color: $content-tone-4 !important;
    }
}

.ql-toolbar.ql-snow {
    background-color: white;
    border: none;
    border-bottom: 1px solid $blue-shade-2;
    @include radii(6px, 6px, 0, 0);
    @include ff($family-cond);

    @media (prefers-color-scheme: dark) {
        background: $neutral-950;
        border-bottom: $neutral-800;
        stroke: $content-tone-4 !important;

        .ql-stroke {
            stroke: $content-tone-4 !important;
        }
        .ql-picker {
            color: $content-tone-4;
        }
        .ql-picker-options {
            background-color: $neutral-900;
        }
    }
}

.ql-container.ql-snow {
    border: 1px solid white;
    @include radii(0, 0, 6px, 6px);
    @include ff($family-cond);
    color: $dark-blue-secondary;
    font-size: 14px;

    @media (prefers-color-scheme: dark) {
        border: transparent;
        // border-bottom: 1px solid $neutral-800;
        color: $content-tone-4;
    }
}

.ql-disabled .ql-editor {
    padding: 0;
}

.content-wrap .ql-editor {
    p {
        text-align: inherit;
    }
}

.ql-align-center {
    text-align: center !important;
}

.ql-align-right {
    text-align: right !important;
}

.ql-align-justify {
    text-align: justify !important;
}
.ql-editor p:last-of-type {
    margin-bottom: 0;
}
