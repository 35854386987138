@import "partials/mixins";

.frat-card-answers {
    @include flex();
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
}

.frat-card-reset {
    .link {
        @include flex();
        align-items: center;
        justify-content: center;
        margin-top: 0;
        width: fit-content;
    }
}
