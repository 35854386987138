@import "variables";

pdf-viewer {
    .textLayer .highlight,
    .textLayer .highlight.selected {
        margin: -2px !important;
        padding: 2px !important;
    }

    .textLayer .highlight {
        background-color: rgba(25, 135, 203, 0.2) !important;
    }

    .textLayer .highlight.selected {
        background-color: rgba(71, 185, 255, 0.6) !important;
    }
}
