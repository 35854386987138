//@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,500,600,700');
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Roboto:300,400,500,700");
@import "partials/variables";
@import "partials/mixins";
@import "partials/material-override";
@import "partials/quill-override";
@import "partials/pdf-viewer-override";
@import "partials/base";
@import "partials/pfm-editor";
@import "tributejs/src/tribute";

@import "primeng/resources/primeng.css";
@import "primeng/themes/lara/lara-light/blue/theme";

@import "partials/frat";
@import "partials/table";
@import "primeflex/primeflex";
@import "primeicons/primeicons.css";
