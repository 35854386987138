@use "partials/variables";

$primaryColor: variables.$light-blue-primary !default;
$primaryLightColor: #bfdbfe !default;
$primaryDarkColor: #1275b3 !default;
$primaryDarkerColor: #0b496e !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #eff6ff !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, 0.24) !default;

@import "../variables";
@import "../../../../theme-base/_components";
@import "../extensions";
